import { FullImgHero } from "../../../Components"
import RightArrow from "../../../Assets/Images/Partners/rightArrow.svg"
import "./MainContent.css"
import { useContext, useEffect } from "react"
import { CategoryID } from "../../../Context/CategoryID"
import PartnersService from "../../../Services/PartnersService"
import usePost from "../../../Custom Hooks/usePost"
import { motion } from "framer-motion"
const MainContent = () => {
    const { categoryId } = useContext(CategoryID)
    const [handleGet, , data] = usePost(PartnersService.getAll)
    useEffect(() => {
        handleGet({ category_id: categoryId })
    }, [categoryId])

    const partners = data?.data?.data?.partners?.map((partner: any, index: any) => {
        if (index != 0) {
            return (
                <motion.div
                    initial={{
                        opacity: 0,
                        y: -200
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0
                    }}
                    transition={{
                        duration: 1.5
                    }}
                    key={partner.id} className="partners-main-con">
                    <FullImgHero additionalClass="ma3jon p-0">
                        <div className="partners-img bg-full"
                            style={{
                                backgroundImage: partner.image != null ? `url(${partner.image})` : ""
                            }}
                        ></div>
                        <div className="partners-logo bg-full"
                            style={{
                                backgroundImage: partner.logo != null ? `url(${partner.logo})` : ""
                            }}></div>
                    </FullImgHero>
                    <div className="flex flex-partners">
                        <p>{partner.description}
                        </p>
                        {partner.link != null ? <a href={`${partner.link}`} target="_blank">{partner.link.slice(8)} <span><img src={RightArrow} alt="right-arrow" /></span></a> : <></>}
                    </div>
                </motion.div>
            )
        }
        else {
            return (
                <div key={partner.id} className="partners-main-con">
                    <FullImgHero additionalClass="ma3jon p-0">
                        <div className="partners-img bg-full"
                            style={{
                                backgroundImage: partner.image != null ? `url(${partner.image})` : ""
                            }}
                        ></div>
                        <div className="partners-logo bg-full"
                            style={{
                                backgroundImage: partner.logo != null ? `url(${partner.logo})` : ""
                            }}></div>
                    </FullImgHero>
                    <div className="flex flex-partners">
                        <p>{partner.description}
                        </p>
                        {partner.link != null ? <a href={`${partner.link}`} target="_blank">{partner.link.slice(8)} <span><img src={RightArrow} alt="right-arrow" /></span></a> : <></>}
                    </div>
                </div>
            )
        }

    }
    )
    return (
        <div className="col-12 col-md-10 p-0 flex-grow-1" >
            {partners}
        </div>
    )
}

export default MainContent