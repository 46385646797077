import { FC } from "react"
import { FullImgHero } from "../"
import { SystemsDataProps } from "../../Assets/Data/OurSystems/SystemsData"
import { useTransform, motion, useScroll } from "framer-motion";
import "./System.css"
type SystemProps = {
    system: SystemsDataProps,
    id: number
}
const System: FC<SystemProps> = ({ system, id }) => {
    const { scrollY } = useScroll()
    const opacity = useTransform(
        scrollY,
        [0, 300],
        [0, 1],
    )
    const y = useTransform(
        scrollY,
        [0, 300],
        [-100, 0],
    )
    return (
        <>
            {id == 2 ? <motion.div
                style={{
                    opacity,
                    y
                }}
                className="systems container-fluid p-0">
                <FullImgHero additionalClass="ma3jon p-0">
                    <div className={`${system.imgClassName} bg-full`}></div>
                    <div className={`${system.logoClassName} bg-full`}></div>
                </FullImgHero>
                <div className="systems-text">
                    <p>
                        {system.descriprion}
                    </p>

                </div>
            </motion.div> :
                <div
                    className="systems container-fluid p-0">
                    <FullImgHero additionalClass="ma3jon p-0">
                        <div className={`${system.imgClassName} bg-full`}></div>
                        <div className={`${system.logoClassName} bg-full`}></div>
                    </FullImgHero>
                    <div className="systems-text">
                        <p>
                            {system.descriprion}
                        </p>

                    </div>
                </div>}
        </>

    )
}

export default System