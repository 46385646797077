import { motion } from "framer-motion"
import { TimeLineData } from "../../../Assets/Data"
import "./TimeLine.css"
const TimeLine = () => {


    const timeLine = TimeLineData.map((item, index) => (
        <div className="date-text-con" key={item.id}>
            <motion.p
                initial={{
                    opacity: 0,
                    y: 200
                }}
                whileInView={{
                    opacity: 1,
                    y: 0
                }}
                transition={{
                    duration: 1.5
                }}
                className="m-0">
                <span>{item.date}</span>
                {item.title}
            </motion.p>
        </div>
    )
    )
    return (
        <div className="small-time-line">
            <div className="container-fluid time-line-con">
                {timeLine}
            </div >
        </div>
    )
}

export default TimeLine