import { fadeLeftAnimation, fadeRightAnimation } from "../../Utils/Motions/Header/HeaderMotions"
import "./Home.css"
import { motion } from "framer-motion"
const Home = () => {
    return (
        <>
            <div className="container-fluid home bg-full">
                <div className="preloader"></div>
                <motion.p
                    variants={fadeLeftAnimation}
                    initial="initial"
                    animate="animate"
                    transition={{
                        duration: 2
                    }}
                >Products that matter, <br />
                    <motion.span
                        variants={fadeRightAnimation}
                        initial="initial"
                        animate="animate"
                        style={{
                            display: "inline-block"
                        }}
                        transition={{
                            duration: 2
                        }}
                    >transforming lives</motion.span>
                </motion.p>
            </div>
        </>
    )
}

export default Home