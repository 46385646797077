import { FullImgHero } from "../../../../Components"
import { Gallery, OurCirtificationDescription } from "../../../../Sections"
import { motion } from "framer-motion"
import "./OurCertification.css"
import { fadeLeftAnimation, fadeRightAnimation } from "../../../../Utils/Motions/Header/HeaderMotions"
const OurCertification = () => {
    return (
        <>
            <FullImgHero additionalClass="our-crtification-hero">
                <div className="text">
                    <motion.h2
                        variants={fadeLeftAnimation}
                        initial="initial"
                        animate="animate"
                        transition={{
                            duration: 1.5
                        }}
                    >Taking Steps towards <br />
                        <motion.span
                            variants={fadeRightAnimation}
                            initial="initial"
                            animate="animate"
                            style={{
                                display: "inline-block"
                            }}
                            transition={{
                                duration: 1.5
                            }}
                        >a Greener Future</motion.span>
                    </motion.h2>
                </div>
            </FullImgHero>
            <OurCirtificationDescription />
            <Gallery />
        </>
    )
}

export default OurCertification