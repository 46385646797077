import { FullImgHero } from "../../../../Components"
import { OurNetworkDescription } from "../../../../Sections"
import { motion } from "framer-motion"
import "./OurNetwork.css"
import { fadeLeftAnimation, fadeRightAnimation } from "../../../../Utils/Motions/Header/HeaderMotions"

const OurNetwork = () => {
    return (
        <>
            <FullImgHero additionalClass="our-network-hero">
                <div className="text">
                    <motion.h2
                        variants={fadeLeftAnimation}
                        initial="initial"
                        animate="animate"
                        transition={{
                            duration: 1.5
                        }}
                    >Leading International Brands<br />
                        <motion.span
                            variants={fadeRightAnimation}
                            initial="initial"
                            animate="animate"
                            style={{
                                display: "inline-block"
                            }}
                            transition={{
                                duration: 1.5
                            }}
                        >Local Presence</motion.span>
                    </motion.h2>
                </div>
            </FullImgHero>
            <OurNetworkDescription />
        </>
    )
}

export default OurNetwork