import { motion } from "framer-motion"
import "./OurNetworkDescription.css"

const OurNetworkDescription = () => {
    return (
        <div className="our-network-description container-fluid">
            <motion.p
                whileInView={{
                    y: 0,
                    opacity: 1
                }}
                initial={{
                    y: 50,
                    opacity: 0
                }}
                transition={{
                    duration: 1
                }}
            >We take pride in our team of professionals and specialists
                who have established a robust external network with leading
                medical and pharmaceutical manufacturers worldwide.
            </motion.p>
            <motion.p
                whileInView={{
                    y: 0,
                    opacity: 1
                }}
                initial={{
                    y: 50,
                    opacity: 0
                }}
                transition={{
                    duration: 1
                }}
            >Our strength is further enhanced by our ability to maintain
                long-term performance and partnerships since our beginnings
                in the 1970s.
            </motion.p>
            <motion.p
                whileInView={{
                    y: 0,
                    opacity: 1
                }}
                initial={{
                    y: 50,
                    opacity: 0
                }}
                transition={{
                    duration: 1
                }}
            >In addition to our strong performance, Boushahri Group
                Medical continues to expand its internal network of clients,
                which includes public health institutions, private sector clinics,
                hospitals, and pharmacies.
            </motion.p>
        </div>
    )
}

export default OurNetworkDescription