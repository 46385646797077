import { MisionVisionData } from "../../../Assets/Data"
import { motion } from "framer-motion"
import "./MisionVision.css"

const MisionVision = () => {
    const visionMision = MisionVisionData.map(item => {
        if (item.id == 1) {
            return (
                <motion.div
                    whileInView={{
                        x: 0,
                        opacity: 1
                    }}
                    initial={{
                        x: -50,
                        opacity: 0
                    }}
                    transition={{
                        duration: 1
                    }}
                    className="col-12 col-md-6" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>
                        {item.description}
                    </p>
                </motion.div>
            )
        }
        else {
            return (
                <motion.div
                    whileInView={{
                        x: 0,
                        opacity: 1
                    }}
                    initial={{
                        x: 50,
                        opacity: 0
                    }}
                    transition={{
                        duration: 1,
                        delay: 1
                    }}
                    className="col-12 col-md-6" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>
                        {item.description}
                    </p>
                </motion.div>
            )
        }

    }
    )
    return (
        <div className="container-fluid vision-mision">
            <div className="row">
                {visionMision}
            </div>
        </div>
    )
}

export default MisionVision