import { ListOfServicesData } from "../../../Assets/Data"
import { motion } from "framer-motion"
import "./ListOfServices.css"

const ListOfServices = () => {
    const listOfServices = ListOfServicesData.map(item => {
        if (item.id == 1) {
            return (
                <motion.div
                    whileInView={{
                        x: 0,
                        opacity: 1
                    }}
                    initial={{
                        x: -50,
                        opacity: 0
                    }}
                    transition={{
                        duration: 1
                    }}
                    className="col" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </motion.div>
            )
        }
        else {
            return (
                <motion.div
                    whileInView={{
                        x: 0,
                        opacity: 1
                    }}
                    initial={{
                        x: 50,
                        opacity: 0
                    }}
                    transition={{
                        duration: 1,
                        delay: 1
                    }}
                    className="col" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </motion.div>
            )
        }

    }
    )
    return (
        <div className="list-of-services container-fluid">
            <div className="row row-cols-1 row-cols-md-2">
                {listOfServices}
            </div>
        </div>
    )
}

export default ListOfServices