import { ListOfWarehouses } from "../../../../Assets/Data"
import { FullImgHero } from "../../../../Components"
import { motion } from "framer-motion"
import "./OurWarehouse.css"

const OurWarehouse = () => {
    const listOfWarehouses = ListOfWarehouses.map(item => {
        if (item.id == 1 || item.id == 3) {
            return (
                <motion.div
                    whileInView={{
                        x: 0,
                        opacity: 1
                    }}
                    initial={{
                        x: -50,
                        opacity: 0
                    }}
                    transition={{
                        duration: 1
                    }}
                    className="col" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </motion.div>
            )
        }
        else if (item.id == 2) {
            return (
                <motion.div
                    whileInView={{
                        y: 0,
                        opacity: 1
                    }}
                    initial={{
                        y: -50,
                        opacity: 0
                    }}
                    transition={{
                        duration: 1
                    }}
                    className="col" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </motion.div>
            )
        }
        else if (item.id == 5) {
            return (
                <motion.div
                    whileInView={{
                        y: 0,
                        opacity: 1
                    }}
                    initial={{
                        y: 50,
                        opacity: 0
                    }}
                    transition={{
                        duration: 1,
                        delay: 1
                    }}
                    className="col" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </motion.div>
            )
        }
        else if (item.id == 4 || item.id == 6) {
            return (
                <motion.div
                    whileInView={{
                        x: 0,
                        opacity: 1
                    }}
                    initial={{
                        x: 50,
                        opacity: 0
                    }}
                    transition={{
                        duration: 1,
                        delay: 1.5
                    }}
                    className="col" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </motion.div>
            )
        }

    }
    )
    return (
        <>
            <FullImgHero additionalClass="our-warehouse-hero" />
            <div className="list-of-warehouses container-fluid">
                <div className="row row-cols-2 row-cols-md-3">
                    {listOfWarehouses}
                </div>
            </div>
        </>
    )
}

export default OurWarehouse