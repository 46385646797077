import { fadeLeftAnimation, fadeRightAnimation } from "../../../Utils/Motions/Header/HeaderMotions"
import "./OurStory.css"
import { motion } from "framer-motion"
const OurStory = () => {

    return (
        <motion.div className="container-fluid our-story">
            <motion.h3
                variants={fadeLeftAnimation}
                animate="animate"
                initial="initial"
                transition={{
                    duration: 1.5
                }}
            >Boushahri Group Medical was established</motion.h3>
            <motion.h2
                variants={fadeRightAnimation}
                animate="animate"
                initial="initial"
                transition={{
                    duration: 1.5
                }}
            >as an independent legal entity in 2009</motion.h2>
            <p
            >Boushahri Group Medical was established in 2009 as an
                independent legal entity, marking our commitment to
                excellence in the medical industry. Over the years, we have
                expanded our presence across various sectors, with a strong
                focus on Pharmaceuticals, Dermatology, and Aesthetics
            </p>
            <motion.p
                whileInView={{
                    y: 0,
                    opacity: 1
                }}
                initial={{
                    y: 50,
                    opacity: 0
                }}
                transition={{
                    duration: 1
                }}
            >
                Today, Boushahri Group Medical is recognized as one of the
                top 10 leading providers of healthcare solutions in Kuwait.
                We take pride in being a trusted partner to both public and
                private healthcare institutions, delivering innovative and
                reliable solutions to meet the needs of our clients
            </motion.p>
            <motion.p
                whileInView={{
                    y: 0,
                    opacity: 1
                }}
                initial={{
                    y: 50,
                    opacity: 0
                }}
                transition={{
                    duration: 1
                }}
            >
                In 2022, we furthered our ambition to lead in the healthcare
                sector by establishing Boushahri Group Medical Co. in Bahrain.
                In 2024, we established our presence in the Sultanate of
                Oman, reinforcing our commitment to providing exceptional
                healthcare solutions and contributing to the healthcare
                landscape in the region. This strategic move is a testament to
                our vision of extending our influence beyond Kuwait and into
                the broader GCC region.
            </motion.p>
        </motion.div>
    )
}

export default OurStory