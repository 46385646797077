import { ListOfGoalsData } from "../../../Assets/Data"
import { motion } from "framer-motion"
import "./ListOfGoals.css"

const ListOfGoals = () => {
    const listOfGoals = ListOfGoalsData.map(listOfGoals => {
        if (listOfGoals.id == 1 || listOfGoals.id == 3) {
            return (
                <motion.div
                    whileInView={{
                        x: 0,
                        opacity: 1
                    }}
                    initial={{
                        x: -50,
                        opacity: 0
                    }}
                    transition={{
                        duration: 1
                    }}
                    className="col" key={listOfGoals.id}>
                    <h2>{listOfGoals.title}</h2>
                    <p>{listOfGoals.description}
                    </p>
                </motion.div>

            )
        }
        else {
            return (
                <motion.div
                    whileInView={{
                        x: 0,
                        opacity: 1
                    }}
                    initial={{
                        x: 50,
                        opacity: 0
                    }}
                    transition={{
                        duration: 1,
                        delay: 1
                    }}
                    className="col" key={listOfGoals.id}>
                    <h2>{listOfGoals.title}</h2>
                    <p>{listOfGoals.description}
                    </p>
                </motion.div>

            )
        }

    }
    )
    return (
        <div className="list-of-goals container-fluid">
            <div className="row row-cols-1 row-cols-md-2">
                {listOfGoals}
            </div>
        </div>
    )
}

export default ListOfGoals