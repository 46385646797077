
import { SystemsData } from "../../../../Assets/Data"
import { System } from "../../../../Components"
import "./OurSystems.css"

const OurSystems = () => {

    const systems = SystemsData.map(system => (
        <System system={system} id={system.id} />
    ))
    return (
        <>
            {systems}
        </>
    )
}

export default OurSystems